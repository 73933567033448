import ReactDOM from "react-dom";
import React, { Component } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CRow,
  CSpinner,
} from "@coreui/react";
import {
  getStorageItem,
  makeUnauthorizedRequest,
  notify,
  setCookie,
  enableEnterkey,
} from "./functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faLock,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { AUTH_URL, CODE_VERIFY_URL } from "./urls";
import request from "request";

const DEFAULT_INPUT_PASSWORD = "";

var access_token = getStorageItem("access_token");

var claims = {};
if (access_token.length > 0) {
  claims = JSON.parse(atob(access_token.split(".")[1]));
}

class PasswordLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: DEFAULT_INPUT_PASSWORD,
      username: claims !== undefined ? claims.username : "",
      otp: "",
      show_otp_view: false,
      loading: false,
      passwordMaskOn: true,
      sp_admin_access_token: "",
    };

    this.loginRequest = this.loginRequest.bind(this);
  }

  handleChange(e) {
    let value = e.target.value;

    var key = e.target.name;

    this.setState({ [key]: value });
  }

  async loginRequest() {
    const setloading = value => {
      this.setState({ loading: value });
    };

    await makeUnauthorizedRequest(
      AUTH_URL,
      "POST",
      this.state,
      setloading,
      res => {
        notify("success", res.message, "Notification");
        this.setState({
          show_otp_view: true,
          sp_admin_access_token: res.accessToken,
        });
      }
    );
  }

  async validateOTP() {
    const setloading = value => {
      this.setState({ loading: value });
    };

    setloading(true);

    if (this.state.otp.length >= 6) {
      var config = {
        url: CODE_VERIFY_URL,
        method: "POST",
        body: {
          username: this.state.username,
          password: this.state.password,
          otp: this.state.otp,
        },
        json: true,
      };

      request(config, function (error, response) {
        setloading(false);
        if (error)
          return console.log(
            `%c ${error.message}`,
            "background: #222; color: #FF0000"
          );
        var data = response.body;

        if (data.status) {
          setCookie("accessToken", data.access, (15 / 60) * 24);

          notify(
            "success",
            data.message ? data.message : data.detail,
            "Notification"
          );
          notify("success", data.message, "Notification");

          if (window.location.href.includes("login")) {
            window.location.replace("#/dashboard/#");
          }
          window.location.reload();

          notify("info", "Session refreshed successfully.", "Login Success");
        } else {
          notify("danger", data.message, "Notification");
        }
      });
    } else {
      notify("danger", "Invalid One Time Password.", "Notification");
    }
  }

  toggleMask() {
    this.setState({ passwordMaskOn: !this.state.passwordMaskOn });
  }

  render() {
    return (
      <div>
        <h4 hidden={this.state.show_otp_view}> Your session has expired</h4>
        <CForm hidden={this.state.show_otp_view} className="form-horizontal">
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="username">Username</CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInputGroup className="mb-3">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <FontAwesomeIcon icon={faUser} />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  type="username"
                  name="username"
                  value={this.state.username}
                  onChange={this.handleChange.bind(this)}
                  placeholder="Username"
                  autoComplete="email"
                />
              </CInputGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="password">Password</CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInputGroup>
                <CInputGroupAppend>
                  <CInputGroupText>
                    <FontAwesomeIcon icon={faLock} />
                  </CInputGroupText>
                </CInputGroupAppend>
                <CInput
                  type={this.state.passwordMaskOn ? "password" : "text"}
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange.bind(this)}
                />
                <CInputGroupPrepend>
                  <CInputGroupText onClick={this.toggleMask.bind(this)}>
                    {" "}
                    <FontAwesomeIcon
                      icon={this.state.passwordMaskOn ? faEye : faEyeSlash}
                    />
                  </CInputGroupText>
                </CInputGroupPrepend>
              </CInputGroup>
            </CCol>
          </CFormGroup>
          <CButton
            disabled={this.state.loading}
            onClick={this.loginRequest}
            className="float-right my-3"
            color="info"
            variant="outline">
            <CSpinner grow size="sm" hidden={!this.state.loading} /> Login
          </CButton>
        </CForm>

        <CForm hidden={!this.state.show_otp_view}>
          {/* <h1>Enter OTP</h1> */}
          <p className="text-muted">
            A one time PIN has been sent to your registered phone number. Enter
            it below to verify your account.
          </p>
          <CInputGroup className="mb-4">
            <CInputGroupPrepend>
              <CInputGroupText>OTP</CInputGroupText>
            </CInputGroupPrepend>
            <CInput
              type="text"
              name="otp"
              value={this.state.otp}
              valid={this.state.otp.length >= 6}
              onChange={this.handleChange.bind(this)}
              onKeyPress={e => {
                enableEnterkey(e, this.validateOTP.bind(this));
              }}
              placeholder="Enter OTP"
            />
          </CInputGroup>
          <CRow>
            <CButton
              disabled={this.state.loading}
              onClick={this.validateOTP.bind(this)}
              color="info"
              className={
                !this.state.loadings ? "mx-3 px-3 btn-block" : "mx-3 btn-block"
              }>
              VALIDATE OTP <small id="timer"> </small>{" "}
              <CSpinner
                className="mx-2"
                grow
                size="sm"
                hidden={!this.state.loading}
              />
            </CButton>
          </CRow>
        </CForm>
      </div>
    );
  }
}

let wrapper = document.createElement("div");
ReactDOM.render(<PasswordLayout />, wrapper);
let el = wrapper.firstChild;

export default el;
