import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import './css/style.css'
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { CSpinner } from '@coreui/react';
import { getCookie, isEmpty, notify, setCookie } from './functions';
import el from './password'
import { connect } from 'react-redux';
var swal = require('sweetalert')

require('dotenv').config()
const loading = (
  <div className="pt-3 text-center align-middle">
    {/* <div className="sk-spinner sk-spinner-pulse"></div> */}
    <CSpinner size='lg' color='danger' grow />
  </div>
)
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages 
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  componentDidMount() {
    var modal_showing = false;

    var x = setInterval(function () {

      try {
        var accessToken = getCookie('accessToken');
        if (accessToken.length > 0) {
          if (window.location.href.includes("login")) {
            console.log(this.props.is_operator)

            window.location.replace("#/dashboard/#")
          }
          var claims = JSON.parse(atob(accessToken.split('.')[1]))

          if (!isEmpty(claims)) {
            var countDownDate = new Date(new Date(claims.exp * 1000)).getTime();
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            var days_seconds = days * 86400

            // Display the result in the element with id="timer"
            if (!isNaN(seconds)) {
              var sec = days_seconds + (seconds + (minutes * 60) + (hours * 60 * 60))
              if (sec > 0 && sec <= 60) {
                if (!modal_showing) {
                  swal({
                    content: el,
                    buttons: false,
                    closeOnClickOutside: false,
                    icon: 'warning',
                    closeOnEsc: false,
                  })
                  modal_showing = true;
                }
              }
              document.getElementById("sysTime").innerHTML = sec;// (seconds + (minutes * 60) + (hours * 60 * 60)) + "s ";
            }
            // If the count down is finished, write some text
            if (distance <= 0) {
              clearInterval(x);
              setCookie("accessToken", "", -7)
              document.getElementById("sysTime").innerHTML = "EXPIRED";
              window.location.replace("#/login/#")
              modal_showing = false;
              notify('danger', 'Your session has expired.', 'Login')
            }
          } else {
            window.location.replace("#/login/#")
          }
        }
        else {
          if (window.location.href.includes("register")) {
          } else {
            window.location.replace("#/login/#")
          }
        }

      } catch (error) {

      }
    }, 3000);
  }
  render() {
    return (
      <HashRouter>
        <ReactNotification />
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_subscriptions: state.user_subscriptions,
    plans: state.plans,
    is_operator: state.isOperator,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAny: (val) => dispatch({ type: 'set', [val.name]: val.value })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)



