import * as firebase from "firebase/app";
import "firebase/messaging";
import "firebase/storage";
var config = null;

// require('dotenv').config()

/*

{
    apiKey: "AIzaSyDUBWT-7QshZ1FAkrsJwNYm0U1SzCPas4o",
    authDomain: "residence-app-8e3b3.firebaseapp.com",
    databaseURL: "https://residence-app-8e3b3.firebaseio.com",
    projectId: "residence-app-8e3b3",
    storageBucket: "residence-app-8e3b3.appspot.com",
    messagingSenderId: "966295285008",
    appId: "1:966295285008:web:e49265235d7e448516c995",
    measurementId: "G-TPTKFGWMLY",
  };
*/

const env = process.env.NODE_ENV || "development";

var cert = "";

if (env === "development") {
  config = {
    apiKey: "AIzaSyAXS3JZ1r9Fg7pfxPV9d34tjJs6I5Rcq6s",
    authDomain: "sasapay-5d0fe.firebaseapp.com",
    databaseURL: "https://sasapay-5d0fe.firebaseio.com",
    projectId: "sasapay-5d0fe",
    storageBucket: "sasapay-5d0fe.appspot.com",
    messagingSenderId: "437784912716",
    appId: "1:437784912716:web:327c265b7adfd02edc25e9",
    measurementId: "G-WKH478BJ7B",
  };

  cert =
    "BOklVRBRAhlY5VnDCSFh01CSxIvIHnRBs5lrAM1ksLaCpi61vAv-2OaLjkQDYCfUfajmw8JClNP3XVgd0akykmQ";
} else {
  config = {
    apiKey: "AIzaSyAXS3JZ1r9Fg7pfxPV9d34tjJs6I5Rcq6s",
    authDomain: "sasapay-5d0fe.firebaseapp.com",
    databaseURL: "https://sasapay-5d0fe.firebaseio.com",
    projectId: "sasapay-5d0fe",
    storageBucket: "sasapay-5d0fe.appspot.com",
    messagingSenderId: "437784912716",
    appId: "1:437784912716:web:327c265b7adfd02edc25e9",
    measurementId: "G-WKH478BJ7B",
  };

  cert =
    "BOklVRBRAhlY5VnDCSFh01CSxIvIHnRBs5lrAM1ksLaCpi61vAv-2OaLjkQDYCfUfajmw8JClNP3XVgd0akykmQ";
}

let messaging;
let requestFirebaseNotificationPermission;
let onMessageListener;
let registerServiceWorker;

if (firebase.messaging.isSupported()) {
  firebase.initializeApp(config);
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(cert);

  requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then((firebaseToken) => {
          resolve(firebaseToken);
        })
        .catch((err) => {
          reject(err);
        });
    });

  onMessageListener = () =>
    new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    });

  registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("firebase-messaging-sw.js")
        .then(function (registration) {
          // eslint-disable-next-line no-console
          console.log("[SW]: SCOPE: ", registration.scope);
          return registration.scope;
        })
        .catch(function (err) {
          return err;
        });
    }
  };
} else {
}
export {
  registerServiceWorker,
  onMessageListener,
  requestFirebaseNotificationPermission,
};
