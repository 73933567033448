/* eslint-disable eqeqeq */
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import { KYC_DOCUMENTS, BASE_URL } from "./urls";
import swal from "sweetalert";

export function enableEnterkey(e, validateOTP) {
  let enterKey = e.keyCode || e.key;
  if (enterKey === 13 || enterKey == "Enter") {
    e.preventDefault();
    validateOTP();
  }
}

export function getStorageItem(cname) {
  var item = localStorage.getItem(cname);

  if (item === null || item === undefined || item === "undefined") {
    return "";
  }

  return item;
}

export function setStorageItem(cname, cvalue, exdays) {
  return localStorage.setItem(cname, cvalue);
}

export function removeStorageItem(cname) {
  return localStorage.removeItem(cname);
}

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function titleCase(string) {
  var sentence = string.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence;
}

export function ValidateEmail(mail) {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
}

export function getFormattedDate() {
  var date = new Date();
  var str =
    date.getFullYear() +
    "" +
    (date.getMonth() + 1) +
    "" +
    date.getDate() +
    "_" +
    date.getHours() +
    "" +
    date.getMinutes() +
    "" +
    date.getSeconds();

  return str;
}

export function convertDate(value) {
  const date = new Date(value);
  return date.toLocaleString(["en-US"], {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getSavedData(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  return x;
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getTodayDate() {
  var date = new Date();
  // var str = date.getFullYear()+'-'+ (date.getMonth() + 1) + "-" + date.getDate() ;
  var str =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

  return str;
}

export const notify = (
  type,
  message,
  title,
  position = "top-right",
  duration = 5000,
  onScreen = false
) => {
  var { store } = require("react-notifications-component");

  store.addNotification({
    title,
    message,
    type,
    insert: position,
    container: position,
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration,
      onScreen,
    },
  });
};

var num =
  "None First Second Third Fourth Fifth Sixth Seventh Eighth Nineth Tenth Eleventh Twelveth Thirteenth Fourteenth Fifteenth Sixteenth Seventeenth Eighteenth Nineteenth".split(
    " "
  );
var tens = "Twenty Thirty Forty Fifty Sixty Seventy Eighty Ninety".split(" ");

export function nthConverter(n) {
  if (n < 20) return num[n];
  var digit = n % 10;
  if (n < 100) return tens[~~(n / 10) - 2] + (digit ? "-" + num[digit] : "");
  if (n < 1000)
    return (
      num[~~(n / 100)] +
      " hundred" +
      (n % 100 == 0 ? "" : " and " + nthConverter(n % 100))
    );
  return (
    nthConverter(~~(n / 1000)) +
    " thousand" +
    (n % 1000 != 0 ? " " + nthConverter(n % 1000) : "")
  );
}
export const makeRequest = async (
  url,
  method,
  body,
  setloading,
  calback,
  error_to_initiator = false
) => {
  var request = require("request");
  var access_token = getCookie("accessToken");

  if (access_token.length === 0) {
    return window.location.replace("#/login/#");
  }

  setloading(true);

  var config = {
    url,
    method,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    json: true,
    body,
  };

  request(config, function (error, response) {
    setloading(false);

    if (error) {
      console.log(`%c ${error.message}`, "background: #222; color: #FF0000");
      if (error.message !== "Forbidden" || error.message !== "Unauthorized") {
        if (error.message === "Failed to fetch") {
          notify("danger", "No Internet Connection", "Notification");
        } else {
          console.log(
            `%c ${error.message}`,
            "background: #222; color: #FF0000"
          );
        }
      }
    } else {
      var data = response.body;

      if (data.status) {
        if (data.message === "Unauthorized") {
        } else {
          calback(data);
        }
      } else {
        if (error_to_initiator) {
          if (data.message === "Unauthorized") {
          } else {
            calback(data);
          }
        } else {
          try {
            if (
              !data.message.includes("Forbidden") &&
              data.message !== "Unauthorized"
            ) {
              if (data.message !== "Not Found") {
                notify("danger", data.message + "", "Notification");
              }
            }
          } catch {
            // notify('danger', "AN UNKNOWN ERROR IS BLOCKING US FROM LOADING SOME DETAILS", 'Notification')
          }
        }
      }
    }
  });
};

export const uploadFiles = async (
  url,
  method,
  body,
  setloading,
  calback,
  error_to_initiator = false
) => {
  var request = require("request");
  var access_token = getCookie("accessToken");

  setloading(true);

  var config = {
    url,
    method,
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "multipart/form-data",
    },
    json: true,
    body,
  };

  request(config, function (error, response) {
    setloading(false);
    if (error) {
      if (error.message !== "Forbidden") {
        if (error.message === "Failed to fetch") {
          notify("danger", "No Internet Connection", "Notification");
        } else {
          notify("danger", error.message, "Notification");
        }
      }
    } else {
      var data = response.body;

      if (data.status) {
        calback(data);
      } else {
        if (error_to_initiator) {
          calback(data);
        } else {
          notify("danger", `Error ${data.message} occurred`, "Notification");
        }
      }
    }
  });
};

export const makeUnauthorizedRequest = async (
  url,
  method,
  body,
  setloading,
  calback
) => {
  var request = require("request");
  // var access_token = getCookie('accessToken')

  setloading(true);

  var config = {
    url,
    method,
    // headers: {
    //     'Authorization': `Bearer ${access_token}`
    // },
    json: true,
    body,
  };

  request(config, function (error, response) {
    setloading(false);
    if (error) {
      notify("danger", "Server is unreachable.", "Notification");
    } else {
      var data = response.body;
      if (data.status) {
        calback(data);
      } else {
        notify("danger", data.detail + "", "Notification");
      }
    }
  });
};

export const getAmount = amount => {
  if (amount === null) {
    return "null";
  } else if (amount === undefined) {
    return "Undefine";
  } else {
    let parts = amount.split(".");
    return `${parts[0]}.${parts[1].slice(0, 2)} KES`;
  }
};

export const getDataUrl = sasapay_logo_img => {
  // Create canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  // Set width and height
  canvas.width = sasapay_logo_img.naturalWidth;
  canvas.height = sasapay_logo_img.naturalHeight;
  // Draw the image
  ctx.drawImage(sasapay_logo_img, 0, 0);
  return canvas.toDataURL("image/png");
};

export const generatePDFReport = (
  report_body,
  column_headers,
  account_details
) => {
  swal({
    title: "Transactions PDF download",
    text: "Please note that this is a password protected document. Once, you download the report, Kindly use the Agency Number as the password",
    icon: "info",
    buttons: true,
  }).then(willChange => {
    if (willChange) {
      const pdfDoc = new jsPDF({
        orientation: "p",
        encryption: {
          userPassword: getCookie("mncc"),
          userPermissions: ["print"],
        },
      });
      const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFont("helvetica");
        doc.setTextColor("#0071ce");
        doc.setFontSize(8);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          if (account_details !== undefined) {
            doc.text(`Generated by: ${account_details.display_name}`, 14, 287, {
              align: "left",
            });
          }

          doc.text(
            `Page ${String(i)}  of ${String(pageCount)}`,
            doc.internal.pageSize.width - 14,
            287,
            { align: "right" }
          );
        }
      };

      // Select the image
      const sasapay_logo = document.querySelector("#sasapay-logo");
      sasapay_logo.addEventListener("load", function (event) {
        const dataUrl = getDataUrl(event.currentTarget);
        console.log(dataUrl);
      });

      pdfDoc.addImage(sasapay_logo, "PNG", 14, 10, 40, 15);
      const date = new Date();
      pdfDoc.setFont("times");

      pdfDoc.setFont("helvetica");

      if (account_details !== undefined) {
        pdfDoc.setFontSize(12);
        pdfDoc.setTextColor("#0071ce");
        pdfDoc.text(`Store Name:`, pdfDoc.internal.pageSize.width / 2, 15, {
          align: "left",
        });
        pdfDoc.setFontSize(10);
        pdfDoc.setTextColor("#a71212");
        pdfDoc.text(
          `${account_details.description} `,
          pdfDoc.internal.pageSize.width / 2 + 24,
          15,
          {
            align: "left",
          }
        );

        pdfDoc.setFontSize(12);
        pdfDoc.setTextColor("#0071ce");
        pdfDoc.text(
          `Acccount Number:`,
          pdfDoc.internal.pageSize.width / 2,
          20,
          {
            align: "left",
          }
        );
        pdfDoc.setFontSize(10);
        pdfDoc.setTextColor("#a71212");
        pdfDoc.text(
          `${account_details.account_number}`,
          pdfDoc.internal.pageSize.width / 2 + 36,
          20,
          {
            align: "left",
          }
        );
      }

      pdfDoc.setFontSize(12);
      pdfDoc.setTextColor("#0071ce");
      pdfDoc.text(`Date :`, pdfDoc.internal.pageSize.width / 2, 25, {
        align: "left",
      });
      pdfDoc.setFontSize(10);
      pdfDoc.setTextColor("#a71212");
      pdfDoc.text(
        `${convertDate(date)}`,
        pdfDoc.internal.pageSize.width / 2 + 14,
        25,
        {
          align: "left",
        }
      );
      // we define the name of our PDF file.
      pdfDoc.autoTable({
        columns: column_headers,
        body: report_body,
        margin: { top: 30 },
        styles: { fontSize: 6 },
      });
      addFooters(pdfDoc);
      pdfDoc.save(`Report${convertDate(date)}.pdf`);

      return pdfDoc;
    }
  });
};
export const getEntityName = channel_code => {
  switch (channel_code) {
    case "00":
      return "SasaPay";
    case "0":
      return "SasaPay";
    case "1":
      return "KCB";
    case "2":
      return "Standard Chartered Bank KE";
    case "3":
      return "Absa Bank";
    case "5":
      return "Bank Of India";
    case "7":
      return "NCBA";
    case "10":
      return "Prime Bank";
    case "11":
      return "Cooperative Bank";
    case "12":
      return "National Bank";
    case "16":
      return "Citibank";
    case "17":
      return "Habib Bank AG Zurich";
    case "18":
      return "Middle East Bank";
    case "19":
      return "Bank of Africa";
    case "23":
      return "Consolidated Bank";
    case "31":
      return "Stanbic Bank";
    case "35":
      return "ABC Bank";
    case "41":
      return "NIC Bank";
    case "49":
      return "Spire Bank";
    case "50":
      return "Paramount Universal Bank";
    case "51":
      return "Kingdom Bank";
    case "53":
      return "Guaranty Bank";
    case "54":
      return "Victoria Commercial Bank";
    case "55":
      return "Guardian Bank";
    case "57":
      return "I&M Bank";
    case "63":
      return "DTB";
    case "66":
      return "Sidian Bank";
    case "68":
      return "Equity Bank";
    case "70":
      return "Family Bank";
    case "72":
      return "Gulf African Bank";
    case "74":
      return "First Community Bank";
    case "78":
      return "KWFT Bank";
    case "63902":
      return "MPesa";
    case "63903":
      return "Airtel Money";
    case "63907":
      return "T-Kash";
    case "63909":
      return "SasaPay";
    default:
      return "";
  }
};

export const logout = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
  localStorage.clear();
};

export const axiosClient = axios.create({
  baseURL: KYC_DOCUMENTS,
});
const access_token = getCookie("accessToken");

export const axiosMainClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${access_token}`,
  },
});
